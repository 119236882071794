/* App.css */
:root {
  background-color: #151515;
}
.app-container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin-top: 50px;
}

.search-container {
  background-color: rgb(0, 0, 0);
  border-radius: 10px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  padding: 20px;
  width: 400px;
  margin-right: 20px;
}

.search-input {
  padding: 10px;
  width: calc(100% - 20px);
  border: none;
  background-color: #151515;
  color: #ffffff;
  border-radius: 5px;
  margin-bottom: 10px;
}

.search-options ul {
  color: #fff;
  list-style: none;
  padding: 0;
  margin: 0;
}

.search-options ul li {
  padding: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  display: flex;
  align-items: center;
  border-radius: 5px;
}

.search-options ul li:hover {
  background-color: #6400fa;
  border-radius: 5px;
}

.icon {
  margin-right: 10px;
  width: 24px;
  height: 24px;
  background-color: #5720c4;
  padding: 5px;
  border-radius: 10px;
}

.search-options ul li span {
  font-weight: bold;
}

.history-container {
  background-color: #070707;
  border-radius: 10px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  padding: 20px;
  width: 400px;

}

.history-container .history-header {
  display: flex;
  color: #fff;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.history-container .history-header h2 {
  margin-top: 0;
}

.history-container ul {
  padding: 0;
  list-style: none;
}

.history-container ul li {
  margin-bottom: 10px;
}

.history-container ul li a {
  color: #9400f0;
  text-decoration: none;
}

.history-container ul li a:hover {
  text-decoration: underline;
}

.clear-button {
  background-color: red;
  color: rgb(255, 255, 255);
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
}

.clear-button:hover {
  background-color: darkred;
}


.top-image {
  width: 100%;
  max-width: 50px; /* Adjust the max-width as needed */
  margin: 0 auto; /* Center the image horizontally */
  margin-top: 10px;
  display: block; /* Ensure the image is displayed as a block element */
  margin-bottom: 20px; /* Add some bottom margin for spacing */
}
.copyright {
  display: flex;
  justify-content: center;
  color: #9400f0;
  font-size: 12px;
  margin-top: 20px;
}
/* Media query for smaller screens */
@media (max-width: 600px) {
  .app-container {
    flex-direction: column; /* Change to column layout on smaller screens */
    align-items: center; /* Center horizontally */
  }
  .history-container {
    margin-top: 20px; 
  }
  .search-container {
    margin-right: 0; /* Remove right margin to center search box */
  }
}
